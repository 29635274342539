import XLSX from 'xlsx';

const excelDownloader = (json_data, filename) => {
    const data = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(wb,`${filename}`);
};

const excelReader = async (filename) => {
    let workbook = XLSX.read(await filename.arrayBuffer());
    let sheetNames = workbook.SheetNames;

    let sheetIndex = 1;

    let df = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[sheetIndex-1]]);
    return df[0].Job_Preference_ID || null;
};

const debounce = (fn, delay) => {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

export {
    excelDownloader,
    excelReader,
    debounce
};
